import styled from 'styled-components'
import { Image } from 'semantic-ui-react'

export const ProfileImg = styled(Image)`
  width: 200px;
  height: 200px;
  border-radius: 100%;
  margin-top: 20px;
`

export const UserName = styled.div`
  font-size: 1.2em;
  font-weight: 900;
  margin-bottom: 10px;
`

interface AssociateTypeProps {
  bold: boolean
}

export const AssociateType = styled.div<Partial<AssociateTypeProps>>`
  display: flex;
  margin-bottom: 5px;
  font-weight: ${props => (props.bold ? '500' : '400')};

  &:last-child {
    margin-bottom: 0;
  }
`

export const AssociateItem = styled.div`
  font-size: 1em;
  &:first-child {
    padding-right: 5px;
  }
  &:last-child {
    padding-left: 5px;
  }
`

export const ContactInfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  padding: 0 10px;

  @media ${({ theme }) => theme.device.sm} {
    width: 500px;
    margin-top: 20px;
  }
`

export const ContactInfoItemImg = styled(Image)`
  width: 37px;
  height: 37px;
  margin-right: 10px;
`

export const ContactInfoItemValue = styled.span`
  font-size: 1em;
`
