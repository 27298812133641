import React from 'react'
import { Loading, PageHeader, Seo } from '../components'
// Components
import { useAuthContext } from '../context/AuthContext'

import { usePrismic } from '../context/PrismicContext'
// Styles
import { Container, Wrapper } from '../styles/common'
import {
  ProfileImg,
  UserName,
  AssociateType,
  AssociateItem,
  ContactInfoItem,
  ContactInfoItemImg,
  ContactInfoItemValue,
} from '../styles/Profile.styles'

const ProfilePage = () => {
  const { qUser, firebaseUser, isEventSite } = useAuthContext()
  const {
    prismicData: { prismicProfilePage },
  } = usePrismic()

  const {
    profile_header,
    default_image,
    phone_number_icon,
    email_icon,
    communication_type_icon,
    text,
    email,
  } = prismicProfilePage

  const profileImgUrl = () => {
    if (qUser?.profileUrl) return qUser.profileUrl

    if (firebaseUser?.photoURL) return firebaseUser.photoURL

    if (firebaseUser?.providerData[0]?.photoURL)
      return firebaseUser.providerData[0].photoURL

    return default_image.url
  }

  if (!qUser)
    return <Loading loading={true} message="Loading..." showBg={isEventSite} />

  return (
    <>
      <Seo title="Profile" />
      <Wrapper>
        <PageHeader exitRoute="/">{profile_header[0].text}</PageHeader>
        <Container align="center" padded>
          <ProfileImg data-qa="profileImage" src={profileImgUrl()} />
        </Container>

        <Container align="center" padded>
          <UserName data-qa="profileUsername">{qUser?.displayName ?? 'User'}</UserName>
          <AssociateType bold>
            <AssociateItem data-qa="profileAssociateType">
              {qUser?.associateType ?? 'Unavailable'}
            </AssociateItem>
            {'|'}
            <AssociateItem data-qa="profileAssociateID">
              {qUser?.legacyAssociateId ?? 'Unavailable'}
            </AssociateItem>
          </AssociateType>

          {qUser?.associateType === 'AMBASSADOR' && (
            <AssociateType>
              <AssociateItem data-qa="profileAssociateUsername">{qUser?.dS_Username ?? 'Username'}</AssociateItem>
            </AssociateType>
          )}
        </Container>
        <Container align="center" padded>
          <ContactInfoItem>
            <ContactInfoItemImg src={phone_number_icon.url} />
            <ContactInfoItemValue data-qa="profilePhoneNumber">
              {qUser?.primaryPhoneNumber ?? 'Unavailable'}
            </ContactInfoItemValue>
          </ContactInfoItem>
          <ContactInfoItem>
            <ContactInfoItemImg src={email_icon.url} />
            <ContactInfoItemValue data-qa="profileEmail">
              {qUser?.emailAddress ?? 'Unavailable'}
            </ContactInfoItemValue>
          </ContactInfoItem>
          <ContactInfoItem>
            <ContactInfoItemImg src={communication_type_icon.url} />
            <ContactInfoItemValue data-qa="profileCommunication">
              {text[0].text}/{email[0].text}
            </ContactInfoItemValue>
          </ContactInfoItem>
        </Container>
      </Wrapper>
    </>
  )
}

export default ProfilePage
